import React, { useEffect, useState } from 'react';
import { Row, Col, Input, Button, Form, Drawer, Select } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { putApi } from 'redux/sagas/putApiSaga';
import { API_STATUS, NOTIFICATION_TYPE, notificationTemplates } from 'constants/app-constants';
import { SERVER_IP } from 'assets/Config';
import { postApi } from 'redux/sagas/postApiDataSaga';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';
import FileUpload from 'components/file-upload/file-upload';

const AddNotification = ({ notificationAddModal, width = '40%', editNotification, setNotificationAddModal, refreshList, handleClose }) => {
	const [form] = Form.useForm();
	const type = Form.useWatch('type', form);
	const globalRedux = useSelector((state) => state.globalRedux);
	const branchId = globalRedux?.selectedBranch?.id;
	const dispatch = useDispatch();
	const [ image, setImage ] = useState([]);

	useEffect(() => {
		if (editNotification) {
			form.setFieldsValue({
				type: editNotification?.type || '',
				template: editNotification?.template || '',
				title: editNotification?.title || '',
				description: editNotification?.description || '',
			});
		} else {
			form?.resetFields();
		}
	}, [editNotification, form]);

	const handleSubmit = (values) => {
		let data = {
			...values,
			branchId,
			image,
		};

		if (!editNotification) {
			dispatch(postApi(data, 'ADD_NOTIFICATION'));
		} else {
			let url = `${SERVER_IP}api/notifications/${editNotification.id}`;
			dispatch(putApi(data, 'EDIT_NOTIFICATION', url));
		}
	};

	useEffect(() => {
		if (globalRedux.apiStatus.ADD_NOTIFICATION === 'SUCCESS' || globalRedux.apiStatus.EDIT_NOTIFICATION === 'SUCCESS') {
			dispatch(resetApiStatus(editNotification ? 'EDIT_NOTIFICATION' : 'ADD_NOTIFICATION'));
			refreshList?.();
			handleClose?.();
			form?.resetFields();
		}
	}, [globalRedux.apiStatus, editNotification, setNotificationAddModal, dispatch, refreshList, handleClose, form]);

	const layer1FormCol = {
		labelCol: {
			span: 8,
		},
		wrapperCol: {
			span: 16,
		},
	};

	const onClose = () => {
		setNotificationAddModal(false);
	};

	const loading = globalRedux.apiStatus.ADD_NOTIFICATION === API_STATUS.PENDING || globalRedux.apiStatus.EDIT_NOTIFICATION === API_STATUS.PENDING;

	// Auto-fill title and description when the template is selected
	const handleValuesChange = (changedValues, allValues) => {
		if (changedValues.template) {
			const selectedTemplate = notificationTemplates[allValues.type]?.find((template) => template.title === changedValues.template);
			if (selectedTemplate) {
				form.setFieldsValue({
					title: selectedTemplate.title,
					description: selectedTemplate.message,
				});
			}
		}
	};

	return (
		<Drawer
			placement="right"
			title={`${editNotification ? 'Edit' : 'New'} Notification`}
			width={width || '30%'}
			open={notificationAddModal}
			closable
			onClose={onClose}
			destroyOnClose={true}>
			<Row>
				<Col span={24}>
					<Row style={{ marginTop: 20 }}>
						<Form
							name="add-notification"
							className="required_in_right"
							style={{ width: '100%' }}
							colon={true}
							labelAlign="left"
							form={form}
							onFinish={handleSubmit}
							initialValues={{}}
							onValuesChange={handleValuesChange}
							{...layer1FormCol}>
							<Form.Item
								label="Type"
								name="type"
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}>
								<Select placeholder="Select Type">
									{NOTIFICATION_TYPE.map((level, index) => (
										<Select.Option key={index} value={level?.value}>
											{level?.label}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
							<Form.Item
								label="Template"
								name="template"
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}>
								<Select disabled={!type} placeholder="Select Template">
									{notificationTemplates?.[form.getFieldValue('type')]?.map((level, index) => (
										<Select.Option key={index} value={level?.title}>
											<span>{level?.title}</span>
											<p
												style={{
													whiteSpace: 'pre-wrap',
												}}>
												{level?.message}
											</p>
										</Select.Option>
									))}
								</Select>
							</Form.Item>
							<Form.Item
								label="Title"
								name="title"
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}>
								<Input placeholder="Enter Title" />
							</Form.Item>
							<Form.Item
								label="Description"
								name="description"
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}>
								<Input.TextArea placeholder="Enter Description" autoSize={{ minRows: 5 }} />
							</Form.Item>
							<FileUpload
								aspect={16 / 9}
								afterUpload={setImage}
								max={1}
								fileList={form.getFieldValue('image')}
								setFileList={setImage}
								action={'/'}
							/>

							<Form.Item
								wrapperCol={{
									offset: 0,
									span: 24,
								}}>
								<Row className="space-between" style={{ paddingTop: 20, width: '100%', margin: 0 }}>
									<Button danger style={{ width: '49%' }} onClick={onClose}>
										Cancel
									</Button>
									<Button loading={loading} style={{ width: '49%' }} type="primary" htmlType="submit">
										{editNotification ? 'Update' : 'Save'}
									</Button>
								</Row>
							</Form.Item>
						</Form>
					</Row>
				</Col>
			</Row>
		</Drawer>
	);
};

export default AddNotification;
