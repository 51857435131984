import { combineReducers } from 'redux';
import storageSession from 'redux-persist/lib/storage/session';
import { persistReducer } from 'redux-persist';
import { globalReducer } from './globals/globalReducer';
import { loginReducer } from './login/loginReducer';
import { customerReducer } from './customers/customerReducer';
import { projectReducer } from './projects/reducer';
import { userReducer } from './users/reducer';
import { itemReducer } from './items/reducer';
import { voucherReducer } from './vouchers/voucherReducer';
import { agentReducer } from './agent/agentReducer';
import { subItemReducer } from './sub-items/reducer';
import { invoiceReducer } from './invoices/invoiceReducer';
import { dashboardReducer } from './dashboard/dashboardReducer';
import { staffReducer } from './staffs/staffReducer';
import { purchaseReducer } from './purchse/purchaseReducer';
import { AccBooksReducer } from './accBooks/accBookReducer';
import { dailyTransactionReducer } from './daily-transaction/dailyTransactionReducer';
import { StudentsReducer } from './students/studentsReducer';
import { ReceiptsReducer } from './receipts/receiptsReducer';
import { StudentsFeesReducer } from './studentFees/studentFeesReducer';
import { LoadInReducer } from './load-in/loadInReducer';
import { ticketReducer } from './tickets/ticketReducer';
import { registerReducer } from './register/registerReducer';
import { supplierReducer } from './suppliers/supplierReducer';
import { coachReducer } from './coaches/coachReducer';
import { membershipReducer } from './memberships/membershipReducer';
import { paymentReducer } from './payments/paymentReducer';
import { productReducer } from './products/productReducer';
import { customerReportReducer } from './customer-reports/customerReportReducer';
import { paymentReportReducer } from './payment-reports/paymentReportReducer';
import { serviceReducer } from './services/serviceReducer';
import { visitorReducer } from './visitors/visitorReducer';
import { outstandingReducer } from './outstanding/outstandingReducer';
import { gymReducer } from './gyms/gymReducer';
import { accessReducer } from './access/accessReducer';
import { personalTrainingReducer } from './personal-training/personalTrainingReducer';
import { gymPaymentReducer } from './gym-payments/paymentReducer';
import { periodReducer } from './periods/periodsReducer';
import { dietPlanReducer } from './diet-plan/dietPlanReducer';
import { dietPlanTemplateReducer } from './diet-plan-template/dietPlanTemplateReducer';
import { foodReducer } from './foods/foodsReducer';
import { notificationReducer } from './notifications/notificationReducer';
import { eventReducer } from './events/eventReducer';

const persistConfig = {
	key: 'root',
	storage: storageSession,
};

const allReducers = combineReducers({
	loginRedux: loginReducer,
	registerRedux: registerReducer,
	globalRedux: globalReducer,
	customerRedux: customerReducer,
	coachRedux: coachReducer,
	customerReportRedux: customerReportReducer,
	paymentReportRedux: paymentReportReducer,
	paymentRedux: paymentReducer,
	outstandingRedux: outstandingReducer,
	membershipRedux: membershipReducer,
	serviceRedux: serviceReducer,
	periodRedux: periodReducer,
	productRedux: productReducer,
	personalTrainingRedux: personalTrainingReducer,
	dietPlanRedux: dietPlanReducer,
	dietPlanTemplateRedux: dietPlanTemplateReducer,
	visitorRedux: visitorReducer,
	supplierRedux: supplierReducer,
	projectRedux: projectReducer,
	userRedux: userReducer,
	itemRedux: itemReducer,
	agentRedux: agentReducer,
	voucherRedux: voucherReducer,
	dashboardRedux: dashboardReducer,
	subItemRedux: subItemReducer,
	invoiceRedux: invoiceReducer,
	purchaseRedux: purchaseReducer,
	staffRedux: staffReducer,
	accBookRedux: AccBooksReducer,
	studentsRedux: StudentsReducer,
	studentsFeesRedux: StudentsFeesReducer,
	receiptsRedux: ReceiptsReducer,
	loadInRedux: LoadInReducer,
	dailyTransactionRedux: dailyTransactionReducer,
	ticketRedux: ticketReducer,
	gymRedux: gymReducer,
	gymPaymentRedux: gymPaymentReducer,
	accessRedux: accessReducer,
	foodRedux: foodReducer,
	notificationRedux: notificationReducer,
	eventRedux: eventReducer
});

export default persistReducer(persistConfig, allReducers);
