import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Descriptions, Modal, Row, Spin, Switch, Table, Tag, Typography, Divider } from 'antd';
import { SERVER_IP } from 'assets/Config';
import { API_STATUS, DATE_FORMAT } from 'constants/app-constants';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';
import { getApi } from 'redux/sagas/getApiDataSaga';
import { capitalizeFirstLetter, getDateFormat, getINRMoneyFormat } from 'services/Utils';
import { putApi } from 'redux/sagas/putApiSaga';

const { Title, Text } = Typography;

const CustomerDetails = ({ id, onCloseCustomerDetails, viewDetails = false }) => {
	const customerRedux = useSelector((state) => state.customerRedux);
	const { selectedCustomerDetails } = customerRedux;
	const globalRedux = useSelector((state) => state.globalRedux);
	const dispatch = useDispatch();

	const columns = [
		{
			title: 'S.No',
			dataIndex: 'serviceId',
			key: 'serviceId',
			render: (text, row, index) => <Text>{index + 1}</Text>,
		},
		{
			title: 'Paid On',
			dataIndex: 'paidOn',
			width: 120,
			render: (text) => getDateFormat(text, DATE_FORMAT.DD_MM_YYYY),
		},
		{
			title: 'Paid For',
			key: 'membership',
			render: (text, record) => (
				<>
					{record.membership && (
						<>
							<Row gutter={[0, 10]}>
								<Col>
									<Tag color="blue">Membership</Tag>
								</Col>
								<Col>
									<Tag color="green">{text.service?.serviceName}</Tag>
								</Col>
								<Col>
									<Tag color="cyan">{text.membership?.membershipName}</Tag>
								</Col>
							</Row>
						</>
					)}
					{record.product && (
						<>
							<Row gutter={[0, 10]}>
								<Col>
									<Tag color="purple">Product</Tag>
								</Col>
								<Col>
									<Tag color="lime">{text.product?.productName}</Tag>
								</Col>
							</Row>
						</>
					)}
					{record.paymentFor === 'Personal Training' && <Tag color="volcano">{record.paymentFor}</Tag>}
				</>
			),
		},
		{
			title: 'Paid',
			dataIndex: 'amount',
			align: 'right',
			render: (text) => getINRMoneyFormat(text),
		},
		{
			title: 'Balance',
			dataIndex: 'balance',
			align: 'right',
			render: (text) => (Number(text) > 0 ? getINRMoneyFormat(text) : 'N/A'),
		},
		{
			title: 'Method',
			dataIndex: 'paymentMethod',
			align: 'center',
			width: 120,
			render: (text, record) => {
				return text === 'upi' ? (
					<Row gutter={[10, 0]}>
						<Col span={24}>
							<Tag color="orange">UPI</Tag>
						</Col>
						<Col span={24}>
							<Text
								style={{
									fontSize: 10,
								}}>
								{record.upiTransactionId}
							</Text>
						</Col>
					</Row>
				) : (
					<Tag color="green">Cash</Tag>
				);
			},
		},
		{
			title: 'Received By',
			dataIndex: 'coach',
			render: (text) => <Text>{text.coachName}</Text>,
		},
	];

	const getCustomerDetails = useCallback(() => {
		if (id) {
			let url = `${SERVER_IP}api/customers/${id}`;
			dispatch(getApi('SET_SELECTED_CUSTOMER_DETAILS', url));
		}
	}, [dispatch, id]);

	useEffect(() => {
		if (globalRedux.apiStatus.SET_SELECTED_CUSTOMER_DETAILS === 'SUCCESS') {
			dispatch(resetApiStatus('SET_SELECTED_CUSTOMER_DETAILS'));
		}
	}, [globalRedux.apiStatus.SET_SELECTED_CUSTOMER_DETAILS, dispatch]);

	useEffect(() => {
		getCustomerDetails();
	}, [getCustomerDetails]);

	const customerDetailsLoading = globalRedux.apiStatus.SET_SELECTED_CUSTOMER_DETAILS === API_STATUS.PENDING;

	const handleActivation = (isActive) => {
		let data = { isActive };
		let url = `${SERVER_IP}api/customers/changeStatus/${selectedCustomerDetails?.id}`;
		dispatch(putApi(data, 'CHANGE_STATUS', url));
	};

	useEffect(() => {
		if (globalRedux.apiStatus.CHANGE_STATUS === 'SUCCESS') {
			getCustomerDetails();
		}
	}, [globalRedux.apiStatus.CHANGE_STATUS, getCustomerDetails]);

	const changingStatus = globalRedux.apiStatus.CHANGE_STATUS === API_STATUS.PENDING;

	return (
		<Modal
			title={
				<Row align="middle" justify="space-between">
					<Title level={4}>Customer Details</Title>
					{/* <Badge
						count={selectedCustomerDetails?.isActive ? 'Active' : 'Inactive'}
						style={{ backgroundColor: selectedCustomerDetails?.isActive ? '#52c41a' : '#ff4d4f' }}
					/> */}
				</Row>
			}
			footer={
				<Button type="primary" onClick={onCloseCustomerDetails}>
					Close
				</Button>
			}
			width={'70%'}
			open={viewDetails}
			centered
			onCancel={onCloseCustomerDetails}>
			<Row>
				{customerDetailsLoading || changingStatus ? (
					<Col
						span={24}
						style={{
							height: 400,
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}>
						<Row gutter={[10, 10]} justify="center" align={'middle'}>
							<Col span={24} style={{ textAlign: 'center' }}>
								<Spin size="large" />
							</Col>
							<Col span={24} style={{ textAlign: 'center' }}>
								<Text>Fetching Customer Details...</Text>
							</Col>
						</Row>
					</Col>
				) : (
					<Col span={24} style={{ padding: 20, paddingTop: 0 }}>
						<Row style={{ marginBottom: 16 }}>
							<Switch
								checkedChildren="Active"
								unCheckedChildren="Inactive"
								checked={selectedCustomerDetails?.isActive}
								onChange={(value) => handleActivation(value)}
							/>
						</Row>
						<Divider orientation="left">Basic Info</Divider>
						<Descriptions bordered>
							<Descriptions.Item label="Name">{selectedCustomerDetails?.customerName}</Descriptions.Item>
							<Descriptions.Item label="Mobile">{selectedCustomerDetails?.mobile}</Descriptions.Item>
							<Descriptions.Item label="Email">{selectedCustomerDetails?.email}</Descriptions.Item>
							<Descriptions.Item label="Gender">
								{selectedCustomerDetails?.gender && capitalizeFirstLetter(selectedCustomerDetails?.gender)}
							</Descriptions.Item>
							<Descriptions.Item label="DOB">{getDateFormat(selectedCustomerDetails?.dob, DATE_FORMAT.DD_MM_YYYY)}</Descriptions.Item>
							<Descriptions.Item label="Age">{selectedCustomerDetails?.age}</Descriptions.Item>
							<Descriptions.Item label="Height">{selectedCustomerDetails?.height}</Descriptions.Item>
							<Descriptions.Item label="Weight">{selectedCustomerDetails?.weight}</Descriptions.Item>
							<Descriptions.Item label="Occupation">{selectedCustomerDetails?.occupation}</Descriptions.Item>
							{selectedCustomerDetails?.referral && (
								<Descriptions.Item label="Referred By">{selectedCustomerDetails?.referral?.customerName}</Descriptions.Item>
							)}
							<Descriptions.Item label="Address">{selectedCustomerDetails?.address}</Descriptions.Item>
						</Descriptions>
						<Divider orientation="left">Payments</Divider>
						{selectedCustomerDetails?.payments?.length > 0 ? (
							<Table columns={columns} dataSource={selectedCustomerDetails?.payments} pagination={{ pageSize: 5 }} />
						) : (
							<Text>No payment records available</Text>
						)}
					</Col>
				)}
			</Row>
		</Modal>
	);
};

export default CustomerDetails;
