import React, { useEffect, useState } from 'react';
import { Row, Col, Input, Button, Form, InputNumber } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { putApi } from 'redux/sagas/putApiSaga';
import { API_STATUS } from 'constants/app-constants';
import { SERVER_IP } from 'assets/Config';
import { postApi } from 'redux/sagas/postApiDataSaga';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';
import RichTextEditor from 'components/rich-text-editor';
import DatePicker from 'components/date-picker';
import FileUpload from 'components/file-upload/file-upload';

const AddEvent = ({ editEvent, refreshList }) => {
	const [form] = Form.useForm();
	const [image, setImage] = useState([]);
	const globalRedux = useSelector((state) => state.globalRedux);
	const branchId = globalRedux?.selectedBranch?.id;
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		if (editEvent) {
			form.setFieldsValue({
				type: editEvent?.type || '',
				template: editEvent?.template || '',
				title: editEvent?.title || '',
				description: editEvent?.description || '',
			});
		} else {
			form?.resetFields();
		}
	}, [editEvent, form]);

	const handleSubmit = (values) => {
		let data = {
			...values,
			branchId,
			image: image,
		};

		if (!editEvent) {
			dispatch(postApi(data, 'ADD_EVENT'));
		} else {
			let url = `${SERVER_IP}api/events/${editEvent.id}`;
			dispatch(putApi(data, 'EDIT_EVENT', url));
		}
	};

	useEffect(() => {
		if (globalRedux.apiStatus.ADD_EVENT === 'SUCCESS' || globalRedux.apiStatus.EDIT_EVENT === 'SUCCESS') {
			dispatch(resetApiStatus(editEvent ? 'EDIT_EVENT' : 'ADD_EVENT'));
			refreshList?.();
			form?.resetFields();
			setImage([]);
			navigate(-1);
		}
	}, [globalRedux.apiStatus, editEvent, dispatch, refreshList, form, navigate]);

	const layer1FormCol = {
		labelCol: {
			span: 4,
		},
		wrapperCol: {
			span: 20,
		},
	};

	const loading = globalRedux.apiStatus.ADD_EVENT === API_STATUS.PENDING || globalRedux.apiStatus.EDIT_EVENT === API_STATUS.PENDING;

	// Auto-fill title and description when the template is selected
	const handleValuesChange = (changedValues, allValues) => {
		if (changedValues.template) {
			// Add logic to auto-fill fields based on template if needed
		}
	};

	return (
		<Row>
			<Col
				span={24}
				style={{
					padding: 20,
				}}>
				<Row style={{ marginTop: 20 }}>
					<Form
						name="add-event"
						className="required_in_right"
						style={{ width: '100%' }}
						colon={true}
						labelAlign="left"
						form={form}
						onFinish={handleSubmit}
						initialValues={{}}
						onValuesChange={handleValuesChange}
						{...layer1FormCol}>
						<Form.Item
							label="Title"
							name="title"
							rules={[
								{
									required: true,
									message: 'This Field is required!',
								},
							]}
							wrapperCol={{
								span: 8,
							}}>
							<Input placeholder="Enter Title" />
						</Form.Item>
						<Form.Item
							label="Description"
							name="description"
							rules={[
								{
									required: true,
									message: 'This Field is required!',
								},
							]}>
							{/* Pass value and onChange for RichTextEditor */}
							<RichTextEditor
								value={form.getFieldValue('description')}
								onChange={(description) => form.setFieldsValue({ description })}
							/>
						</Form.Item>
						<Form.Item
							label="Location"
							name="location"
							rules={[
								{
									required: true,
									message: 'This Field is required!',
								},
							]}
							wrapperCol={{
								span: 8,
							}}>
							<Input placeholder="Enter Location" />
						</Form.Item>
						<Form.Item
							label="Contact"
							name="contact"
							rules={[
							]}
							wrapperCol={{
								span: 8,
							}}>
							<Input placeholder="Enter Contact" />
						</Form.Item>
						<Form.Item
							label="Entry Fees"
							name="entry_fees"
							rules={[
							]}
							wrapperCol={{
								span: 8,
							}}>
							<InputNumber placeholder="Enter Entry Fees" defaultValue={0} />
						</Form.Item>
						<Form.Item
							label="Start Date & Time"
							name="startDate"
							rules={[
								{
									required: true,
									message: 'This Field is required!',
								},
							]}
							wrapperCol={{
								span: 8,
							}}>
							<DatePicker showTime={true} />
						</Form.Item>
						<Form.Item
							label="End Date & Time"
							name="endDate"
							rules={[
								{
									required: true,
									message: 'This Field is required!',
								},
							]}
							wrapperCol={{
								span: 8,
							}}>
							<DatePicker showTime={true} />
						</Form.Item>
						<Form.Item
							label="Venue Name"
							name="venueName"
							rules={[
								{
									required: false,
									message: 'This Field is required!',
								},
							]}
							wrapperCol={{
								span: 8,
							}}>
							<Input placeholder="Enter Venue Name" />
						</Form.Item>
						<Form.Item
							label="Gates Open By"
							name="gatesOpenBy"
							rules={[
								{
									required: false,
									message: 'This Field is required!',
								},
							]}
							wrapperCol={{
								span: 8,
							}}>
							<DatePicker showTime={true} />
						</Form.Item>
						<FileUpload
							aspect={16 / 9}
							afterUpload={setImage}
							max={1}
							fileList={form.getFieldValue('image')}
							setFileList={setImage}
							action={'/'}
						/>
						<Form.Item
							wrapperCol={{
								offset: 0,
								span: 24,
							}}>
							<Row justify="end" style={{ paddingTop: 20, width: '100%', margin: 0 }}>
								<Col span={8}>
									<Row justify="end" gutter={[10, 10]}>
										<Col>
											<Button danger onClick={() => navigate(-1)}>
												Cancel
											</Button>
										</Col>
										<Col>
											<Button loading={loading} type="primary" htmlType="submit">
												{editEvent ? 'Update' : 'Save'}
											</Button>
										</Col>
									</Row>
								</Col>
							</Row>
						</Form.Item>
					</Form>
				</Row>
			</Col>
		</Row>
	);
};

export default AddEvent;
