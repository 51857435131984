import React, { useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'; // Required CSS
import './RichTextEditor.css'; // Import custom styles
import draftToHtml from 'draftjs-to-html';

// RichTextEditor component with image upload feature
const RichTextEditor = ({ value, onChange }) => {
  const [editorState, setEditorState] = useState(value || EditorState.createEmpty());

  // Handle editor changes and send the data back to the parent component
  const onEditorChange = (state) => {
    setEditorState(state);
    const contentState = convertToRaw(state.getCurrentContent());
    const markup = draftToHtml(
      contentState, 
    );
    onChange(markup);  // Send the raw content to the parent
  };

  // Image upload handler
  const handleImageUpload = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      
      reader.onloadend = () => {
        // For base64 image handling:
        const contentState = editorState.getCurrentContent();
        const contentWithImage = contentState.createEntity('IMAGE', 'IMMUTABLE', {
          src: reader.result, // Image as base64
        });
        const entityKey = contentWithImage.getLastCreatedEntityKey();
        const newEditorState = EditorState.set(editorState, {
          currentContent: contentWithImage,
        });
        setEditorState(newEditorState);

        resolve({ data: { link: reader.result } });  // Return the base64 URL
      };

      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);  // Read the file as base64
    });
  };

  // function uploadImageCallBack(file) {
  //   return new Promise(
  //     (resolve, reject) => {
  //       const xhr = new XMLHttpRequest();
  //       xhr.open('POST', 'https://api.imgur.com/3/image');
  //       xhr.setRequestHeader('Authorization', 'Client-ID 991a0e15ea25453');
  //       const data = new FormData();
  //       data.append('image', file);
  //       xhr.send(data);
  //       xhr.addEventListener('load', () => {
  //         const response = JSON.parse(xhr.responseText);
  //         resolve(response);
  //       });
  //       xhr.addEventListener('error', () => {
  //         const error = JSON.parse(xhr.responseText);
  //         reject(error);
  //       });
  //     }
  //   );
  // }

  const toolbarOptions = {
    options: ['inline', 'blockType', 'emoji', 'list', 'textAlign', 'history', 'image'],
    image: {
      uploadCallback: handleImageUpload,  // Image upload handler
      previewImage: true,  // Optionally, enable image preview
    },
  };

  return (
    <div className="editor-container">
      <Editor
        editorState={editorState}
        onEditorStateChange={onEditorChange}
        toolbar={{
          // options: ['inline', 'blockType', 'emoji', 'list', 'textAlign', 'history', 'image'],
          inline: { inDropdown: true },
          list: { inDropdown: true },
          textAlign: { inDropdown: true },
          link: { inDropdown: true },
          history: { inDropdown: true },
          image: { uploadCallback: handleImageUpload, previewImage: true, alt: { present: true, mandatory: true } },
        }}
        wrapperClassName="editor-wrapper"
        editorClassName="editor"  // Apply custom editor styles
      />
    </div>
  );
};

export default RichTextEditor;
