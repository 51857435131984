import React, { useEffect, useMemo, useState } from 'react';
import { Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import useEventListener from 'hooks/handlePasteFiles';

const FileUpload = (props) => {
	useEventListener('paste', (e) => {
		if (e.clipboardData.files.length > 0) {
			const files = Array.from(e.clipboardData.files);
			props.beforeUpload && props.beforeUpload(files[0]);
		}
	});

	const fileList1 = useMemo(() => (props.fileList ? props.fileList : []), [props.fileList]);
	const [fileList, setFileList] = useState(fileList1);

	const onChange = ({ fileList: newFileList }) => {
		setFileList(newFileList);
		props.afterUpload && props.afterUpload(newFileList);
	};

	useEffect(() => {
		setFileList(fileList1);
	}, [fileList1]);

	const onPreview = async (file) => {
		let src = file.url;
		if (!src) {
			src = await new Promise((resolve) => {
				const reader = new FileReader();
				reader.readAsDataURL(file.originFileObj);
				reader.onload = () => resolve(reader.result);
			});
		}
		const image = new Image();
		image.src = src;
		const imgWindow = window.open(src);
		imgWindow.document.write(image.outerHTML);
	};

	const beforeUpload = (file) => {
		const isValidType = ['image/jpeg', 'image/png'].includes(file.type);
		if (!isValidType) {
			props.onError && props.onError('You can only upload JPG/PNG files!');
			return Upload.LIST_IGNORE;
		}

		// Optional: Ensure good resolution by validating file size or processing image
		if (file.size / 1024 / 1024 > 5) {
			props.onError && props.onError('Image must be smaller than 5MB!');
			return Upload.LIST_IGNORE;
		}

		let reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
			setFileList((prevList) => {
				const newList = prevList.map((item) =>
					item.uid === file.uid ? { ...item, thumbUrl: reader.result } : item
				);
				props.setFileList && props.setFileList(newList);
				return newList;
			});
		};

		return props.action === '/' ? false : true;
	};

	return (
		<ImgCrop
			aspect={props.aspect || 1} // Maintain a square aspect ratio by default
			rotationSlider
			quality={1} // Set quality to maximum for cropped images
			showGrid
			showReset
		>
			<Upload
				action={props.action || null}
				listType={props.listType || 'picture-card'}
				fileList={fileList}
				className={props.className}
				onChange={onChange}
				beforeUpload={beforeUpload}
				onPreview={onPreview}
			>
				{fileList.length < (props.max || 1) && '+ Upload'}
			</Upload>
		</ImgCrop>
	);
};

export default FileUpload;
