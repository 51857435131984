import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Row } from 'antd';
// import { EditOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { getApi } from 'redux/sagas/getApiDataSaga';
import { SERVER_IP } from 'assets/Config';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';
import { formQueryStringFromObject, generatePagination } from 'helpers';
import { API_STATUS, NOTIFICATION_TYPE } from 'constants/app-constants';
import { getDateFormat } from 'services/Utils';
import NotificationListPresentational from './notification-list-presentational';

const initialPageSize = 10;
const initialPageSizeOptions = [10, 15, 20];

const NotificationListFunctional = React.memo(() => {
	const notificationRedux = useSelector((state) => state.notificationRedux);
	const globalRedux = useSelector((state) => state.globalRedux);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const dispatch = useDispatch();

	const [notificationAddModal, setNotificationAddModal] = useState(false);
	const [searchKey, setSearchKey] = useState('');
	const [tableData, setTableData] = useState(notificationRedux.notifications);
	const [editNotification, setEditNotification] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(initialPageSize);

	const getNotifications = useCallback(() => {
		const string = formQueryStringFromObject({
			branchId: globalRedux?.selectedBranch?.id,
		});
		let url = `${SERVER_IP}api/notifications?${string}`;
		dispatch(getApi('GET_NOTIFICATIONS', url));
	}, [dispatch, globalRedux?.selectedBranch?.id]);

	useEffect(() => {
		getNotifications();
	}, [getNotifications]);

	useEffect(() => {
		generatePagination(tableData);
	}, [tableData]);

	useEffect(() => {
		let doIt = false;
		if (globalRedux.apiStatus.DELETE_NOTIFICATION === 'SUCCESS') {
			dispatch(resetApiStatus('DELETE_NOTIFICATION'));
			setSelectedRowKeys([]);
			doIt = true;
		}
		if (doIt) {
			getNotifications();
		}
	}, [globalRedux.apiStatus.DELETE_NOTIFICATION, dispatch, getNotifications]);

	useEffect(() => {
		setTableData(notificationRedux.notifications);
	}, [notificationRedux.notifications]);

	const filteredData = useMemo(() => {
		if (searchKey === '') {
			return tableData;
		}
		return tableData.filter((notification) => {
			return (notification?.notificationName || '')?.toLowerCase().includes(searchKey.toLowerCase());
		});
	}, [tableData, searchKey]);

	// const handleDrawer = (rowData) => {
	// 	setEditNotification(rowData);
	// 	setNotificationAddModal(true);
	// };

	const handleAddNotification = () => {
		setEditNotification(null);
		setNotificationAddModal(true);
	};

	const column = [
		{
			title: '#',
			dataIndex: 'notificationNumber',
			key: 'notificationNumber',
			// fixed: 'left',
			width: '5%',
			render: (value, record, index) => <div>{index + 1}</div>,
		},
		{
			title: 'Notification Type',
			dataIndex: 'type',
			key: 'type',
			width: '20%',
			sorter: (a, b) => a?.type?.localeCompare(b?.type),
			render: (value, record) => (
				<Row align={'middle'}>
					<div style={{ fontWeight: 'bold' }}>
						{NOTIFICATION_TYPE?.find((type) => type.value === value)?.label}
					</div>
				</Row>
			),
		},
		// {
		// 	title: 'Template',
		// 	dataIndex: 'template',
		// 	align: 'left',
		// 	width: '15%',
		// },
		{
			title: 'Title',
			dataIndex: 'title',
			align: 'left',
			width: '15%',
		},
		{
			title: 'Description',
			dataIndex: 'description',
			align: 'left',
			width: '45%',
		},
		{
			title: 'Count',
			dataIndex: 'count',
			align: 'left',
			width: '7%',
		},
		{
			title: 'Status',
			dataIndex: 'status',
			align: 'left',
			width: '8%',
		},
		{
			title: 'Created Date',
			dataIndex: 'created_at',
			align: 'left',
			width: '15%',
			render: (value) => getDateFormat(value),
		},
		// {
		// 	title: 'Action',
		// 	align: 'center',
		// 	dataIndex: 'notificationName',
		// 	render: (value, row, index) => {
		// 		return (
		// 			<Row justify="center">
		// 				<Col className="edit_icon" onClick={() => handleDrawer(row)}>
		// 					<EditOutlined />
		// 				</Col>
		// 			</Row>
		// 		);
		// 	},
		// },
	];

	const handleTableChange = (currentPage, pageSize) => {
		setCurrentPage(currentPage);
		setPageSize(pageSize);
	};

	const getStartingValue = () => {
		if (currentPage === 1) return 1;
		else {
			return (currentPage - 1) * pageSize + 1;
		}
	};

	const getEndingValue = () => {
		if (currentPage === 1) return tableData.length < pageSize ? tableData.length : pageSize;
		else {
			let end = currentPage * pageSize;
			return end > tableData.length ? tableData.length : end;
		}
	};

	const tableLoading = useMemo(() => globalRedux.apiStatus.GET_NOTIFICATIONS === API_STATUS.PENDING, [globalRedux.apiStatus]);

	const onSelectChange = (selectedRowKeys) => {
		setSelectedRowKeys(selectedRowKeys);
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
		// fixed: true,
	};

	const handleClose = useCallback(() => {
		setNotificationAddModal(false);
		setEditNotification(null);
	}, [setNotificationAddModal, setEditNotification]);

	return (
		<NotificationListPresentational
			{...{
				filteredData,
				column,
				tableLoading,
				rowSelection,
				selectedRowKeys,
				handleAddNotification,
				currentPage,
				pageSize,
				initialPageSizeOptions,
				initialPageSize,
				handleTableChange,
				setSearchKey,
				getStartingValue,
				getEndingValue,
				notificationAddModal,
				setNotificationAddModal,
				refreshList: getNotifications,
				editNotification,
				handleClose,
			}}
		/>
	);
});

export default NotificationListFunctional;
