import { SERVER_IP } from '../assets/Config';

const API_ENDPOINTS = {
	LOGIN: `${SERVER_IP}api/users/login`,
	REGISTER: `${SERVER_IP}auth/sign-up`,
	GET_ALL_BRANCHES: `${SERVER_IP}api/branches`,
	ADD_STUDENT: `${SERVER_IP}student`,
	ADD_VOUCHER: `${SERVER_IP}voucher`,
	ADD_RECEIPT: `${SERVER_IP}receipt`,
	ADD_LOAD_IN: `${SERVER_IP}load`,
	GET_ALL_PROJECTS: `${SERVER_IP}project`,
	GET_PROJECTS: `${SERVER_IP}project`,
	ADD_PROJECT: `${SERVER_IP}project`,
	ADD_MILESTONE: `${SERVER_IP}milestone`,
	ADD_ITEM: `${SERVER_IP}item`,
	ADD_SUB_ITEM: `${SERVER_IP}subitem`,
	ADD_CUSTOMER: `${SERVER_IP}api/customers`,
	ADD_PAYMENT: `${SERVER_IP}api/payments`,
	ADD_GYM_PAYMENT: `${SERVER_IP}api/gymPayments`,
	ADD_ACCESS: `${SERVER_IP}api/access`,
	ADD_COACH: `${SERVER_IP}api/coaches`,
	ADD_MEMBERSHIP: `${SERVER_IP}api/memberships`,
	ADD_PERIOD: `${SERVER_IP}api/period`,
	ADD_FOOD: `${SERVER_IP}api/food`,
	ADD_DIET_PLAN: `${SERVER_IP}api/dietPlan`,
	ADD_SERVICE: `${SERVER_IP}api/services`,
	ADD_PRODUCT: `${SERVER_IP}api/products`,
	ADD_PERSONAL_TRAINING: `${SERVER_IP}api/personalTraining`,
	ADD_USER: `${SERVER_IP}api/users`,
	ADD_SUPPLIER: `${SERVER_IP}supplier`,
	ADD_AGENT: `${SERVER_IP}agent`,
	ADD_DAILY_TRANSACTION: `${SERVER_IP}voucher`,
	ADD_DAILY_PRODUCTION: `${SERVER_IP}production`,
	ADD_INVOICE: `${SERVER_IP}invoice`,
	ADD_TICKET: `${SERVER_IP}ticket`,
	ADD_BRANCH: `${SERVER_IP}api/branches`,
	ADD_ACCOUNT_BOOK: `${SERVER_IP}accbook`,
	ADD_SALES_PERSON: `${SERVER_IP}salesperson`,
	ADD_VOUCHER_HEAD: `${SERVER_IP}voucherhead`,
	ADD_ASSETS: `${SERVER_IP}asset`,
	ADD_VEHICLE: `${SERVER_IP}vehicle`,
	ADD_VENDOR: `${SERVER_IP}vendor`,
	ADD_CREDENTIAL: `${SERVER_IP}credential`,
	ADD_PART_NUMBER: `${SERVER_IP}partnumber`,
	ADD_BATCH: `${SERVER_IP}batch`,
	ADD_CLASS: `${SERVER_IP}class`,
	ADD_BATCH_BALANCE: `${SERVER_IP}batchbalance`,
	ADD_VISITOR: `${SERVER_IP}api/visitors`,
	GET_GYM_SB: `${SERVER_IP}api/users/sb`,
	ADD_NOTIFICATION: `${SERVER_IP}api/notifications`,
	ADD_EVENT: `${SERVER_IP}api/events`,
};

export default API_ENDPOINTS;
