import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Row, Col, Image } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { getApi } from 'redux/sagas/getApiDataSaga';
import { SERVER_IP } from 'assets/Config';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';
import { formQueryStringFromObject, generatePagination } from 'helpers';
import { API_STATUS } from 'constants/app-constants';
import { getDateFormat } from 'services/Utils';
import EventListPresentational from './event-list-presentational';
import EventSVG from '../../../../assets/images/event.svg';

const initialPageSize = 10;
const initialPageSizeOptions = [10, 15, 20];

const EventListFunctional = React.memo(() => {
	const eventRedux = useSelector((state) => state.eventRedux);
	const globalRedux = useSelector((state) => state.globalRedux);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const dispatch = useDispatch();

	const [eventAddModal, setEventAddModal] = useState(false);
	const [searchKey, setSearchKey] = useState('');
	const [tableData, setTableData] = useState(eventRedux.events);
	const [editEvent, setEditEvent] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(initialPageSize);

	const getEvents = useCallback(() => {
		const string = formQueryStringFromObject({
			branchId: globalRedux?.selectedBranch?.id,
		});
		let url = `${SERVER_IP}api/events?${string}`;
		dispatch(getApi('GET_EVENTS', url));
	}, [dispatch, globalRedux?.selectedBranch?.id]);

	useEffect(() => {
		getEvents();
	}, [getEvents]);

	useEffect(() => {
		generatePagination(tableData);
	}, [tableData]);

	useEffect(() => {
		let doIt = false;
		if (globalRedux.apiStatus.DELETE_EVENT === 'SUCCESS') {
			dispatch(resetApiStatus('DELETE_EVENT'));
			setSelectedRowKeys([]);
			doIt = true;
		}
		if (doIt) {
			getEvents();
		}
	}, [globalRedux.apiStatus.DELETE_EVENT, dispatch, getEvents]);

	useEffect(() => {
		setTableData(eventRedux.events);
	}, [eventRedux.events]);

	const filteredData = useMemo(() => {
		if (searchKey === '') {
			return tableData;
		}
		return tableData.filter((event) => {
			return (event?.eventName || '')?.toLowerCase().includes(searchKey.toLowerCase());
		});
	}, [tableData, searchKey]);

	const handleDrawer = (rowData) => {
		setEditEvent(rowData);
		setEventAddModal(true);
	};

	const handleAddEvent = () => {
		setEditEvent(null);
		setEventAddModal(true);
	};

	const column = [
		{
			title: '#',
			dataIndex: 'eventNumber',
			key: 'eventNumber',
			// fixed: 'left',
			render: (value, record, index) => <div>{index + 1}</div>,
		},
		{
			title: 'Pic',
			dataIndex: 'customerName',
			key: 'customerName',
			width: 50,
			fixed: 'left',
			render: (value, record) => {
				return (
					<Row align={'middle'}>
						<Col
							style={{
								paddingRight: '10px',
							}}>
							{record?.image?.[0]?.url ? (
								<Image
									src={SERVER_IP + record?.image?.[0]?.url}
									alt="Avatar"
									style={{ width: '40px', height: '40px', borderRadius: 5 }}
								/>
							) : (
								<img src={EventSVG} alt="Avatar" style={{ width: '40px', height: '40px', borderRadius: 5 }} />
							)}
						</Col>
					</Row>
				);
			},
		},
		{
			title: 'Title',
			dataIndex: 'title',
			align: 'left',
			width: '15%',
		},
		{
			title: 'Location',
			dataIndex: 'location',
			align: 'left',
			width: '15%',
			render: (value) => (
				<a href={value} target="_blank" rel="noopener noreferrer">
					{value}
				</a>
			),
		},
		{
			title: 'Start Date',
			dataIndex: 'startDate',
			align: 'left',
			width: '15%',
			render: (value) => getDateFormat(value),
		},
		{
			title: 'End Date',
			dataIndex: 'endDate',
			align: 'left',
			width: '15%',
			render: (value) => getDateFormat(value),
		},
		{
			title: 'Created Date',
			dataIndex: 'created_at',
			align: 'left',
			width: '15%',
			render: (value) => getDateFormat(value),
		},
		{
			title: 'Action',
			align: 'center',
			dataIndex: 'eventName',
			render: (value, row, index) => {
				return (
					<Row justify="center">
						<Col className="edit_icon" onClick={() => handleDrawer(row)}>
							<EditOutlined />
						</Col>
					</Row>
				);
			},
		},
	];

	const handleTableChange = (currentPage, pageSize) => {
		setCurrentPage(currentPage);
		setPageSize(pageSize);
	};

	const getStartingValue = () => {
		if (currentPage === 1) return 1;
		else {
			return (currentPage - 1) * pageSize + 1;
		}
	};

	const getEndingValue = () => {
		if (currentPage === 1) return tableData.length < pageSize ? tableData.length : pageSize;
		else {
			let end = currentPage * pageSize;
			return end > tableData.length ? tableData.length : end;
		}
	};

	const tableLoading = useMemo(() => globalRedux.apiStatus.GET_EVENTS === API_STATUS.PENDING, [globalRedux.apiStatus]);

	const onSelectChange = (selectedRowKeys) => {
		setSelectedRowKeys(selectedRowKeys);
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
		// fixed: true,
	};

	const handleClose = useCallback(() => {
		setEventAddModal(false);
		setEditEvent(null);
	}, [setEventAddModal, setEditEvent]);

	return (
		<EventListPresentational
			{...{
				filteredData,
				column,
				tableLoading,
				rowSelection,
				selectedRowKeys,
				handleAddEvent,
				currentPage,
				pageSize,
				initialPageSizeOptions,
				initialPageSize,
				handleTableChange,
				setSearchKey,
				getStartingValue,
				getEndingValue,
				eventAddModal,
				setEventAddModal,
				refreshList: getEvents,
				editEvent,
				handleClose,
			}}
		/>
	);
});

export default EventListFunctional;
